import React from 'react';
import Layout from './src/components/Layout';

export const wrapPageElement = ({ element, props }) => {
  return (
      <Layout
          className={`page ${decodeURI(props.path.replaceAll('/', ''))}`}
          {...props}>
        {element}
      </Layout>
  );
};

export function shouldUpdateScroll(prevRouterProps, { location }) {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}
